var elNewsletter = document.getElementById('newsletter');

if (elNewsletter) {
	// If newsletterblock exists

	$('#newsletter-submit').click(function() {

		newsletter.register(elNewsletter);

	});

	$('#newsletter').click(function(){
		$('#overlay').addClass('active');
	});

	$('#overlay-close').click(function(){
		$('#overlay').removeClass('active');
	});

}

var newsletter = {

	register : function(elNewsletter) {

		//var input = elNewsletter.getElementsByClassName('newsletter-email')[0];

		var input = document.getElementById('newsletter-email');
		var email = input.value;

		// Prepare query string and send AJAX request to register to mailchimp
		$.ajax({
			url: origin.templateDir+'/inc/classes/mailchimp.php',
			data: 'ajax=true&email=' + email,
			success: function(msg) {

			
					var elError = document.getElementById('newsletter-error');
					elError.innerHTML = msg;
		

	
			}
		});

	}

};

