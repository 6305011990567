function setFooterSliderSize() {
	var targetBlockWidth = liWidth;
	// Now on mobile devices we want to make sure that people can see that there is a reason to slide this slider.
	// So a second article needs to be partially visible. Which means all of them should be ± 80% of the viewport width.
	if($(window).width() < 768) { // 768 is the bootstrap breaking point at which XS turns into SM
		targetBlockWidth = ( 0.8 * $(window).width() );
	}
	$("#footer-featured-articles-slider .featured-article").width(targetBlockWidth);
	// Then it is just a simple calculation: X times the width of one slide is the width of the container.
	ul.width( (targetBlockWidth * slideCount) );

	ulWidth = ul.width();
}

function panHandler(ev) {
	// This function checks if movement is required, and if so, applies the movement.
	var target = pos;
	if(ev !== null && typeof ev === 'object') {
		target = pos + ev.deltaX; // target position
	}

	if(target < 0 && target > limit ) {
		if(ev.isFinal === true) {

			pos = pos + ev.deltaX;
			doTransform(pos);
		}
		else {
			var tempPos = pos+ev.deltaX;
			doTransform(tempPos);
		}
	}
	else {

		if(target > 0) {
			pos = 0;
		}
		else if(target < limit) {
			pos = limit;
		}
		doTransform(pos);
	}
}

function doTransform(pos) {

	if(!Modernizr.touch) {


		// Right edge
		if(pos<(limit+5) ) {
			$(".featured-articles .arrow-gradient-right").fadeOut("slow");
		}
		else {
			$(".featured-articles .arrow-gradient-right").fadeIn("slow");
		}

		//Left edge
		if(pos>-5 && pos <= 0 ) {
			$(".featured-articles .arrow-gradient-left").fadeOut("slow");
		}
		else {
			$(".featured-articles .arrow-gradient-left").fadeIn("slow");
		}
	}

	var transform = "translateX("+pos+"px)";
	jsUl.style.webkitTransform = transform;
	jsUl.style.MozTransform = transform;
	jsUl.style.msTransform = transform;
	jsUl.style.OTransform = transform;
	jsUl.style.transform = transform;
}

function doHover(direction) {
	if(direction == "left") {
		pos=pos+2;
	}
	else {
		pos=pos-2;
	}

	if(pos > 0) {
		pos = 0;
	}
	else if(pos < limit) {
		pos = limit;
	}
	else {
		doTransform(pos);
	}
}

// This is set in the view (footer.php in the theme folder). That way, no JS will be loaded which is not required.
if(doFooterSlider === true) {

	// Set the footer slider total width (all li's in the ul of the slider together)
	// First gather some vars: the container, one of the items (they are equal width), the amount of slides
	var ul = $(".featured-articles-slider");
	var li = $(".featured-articles-slider .featured-article").first();
	var liWidth = li.width();
	var slideCount = $(".featured-articles-slider .featured-article").length;

	var ulWidth = ul.width();

	$(".featured-articles .arrow-gradient-left").hide(); // hide on init

	// Set the size on page load
	setFooterSliderSize();

	// And every time the viewport might get scaled.
	$(window).resize(function() {
		setFooterSliderSize();
		panHandler();
	});

	// Yeah, I know. Hammer JS wants us to get a vanilla JS element, not a jQuery-type object.
	var jsUl = document.getElementById("footer-featured-articles-slider");

	// To add options later, should the need arise.
	var hammerOptions = {

	};

	// Create hammer instance
	var hammerSlider = new Hammer(jsUl, hammerOptions);

	// Set a position var, defaults to 0 (no movement in position)
	var pos = 0;

	// First of all, we'll need to set a limit. This is how far the scrolling can go at the max. So this is the width of the container minus te width of the viewport.
	var limit = (ulWidth * -1 ) + $(window).width(); // width of slider minus width of screen: last one needs to be hugging right-hand side of screen

	if(!Modernizr.touch) {
		$(".featured-articles .arrow-gradient").css("display", "block");

		var timeOut = null;

		$(".featured-articles .arrow-gradient-right").mouseenter(function() {
			timeOut = setInterval(doHover, 10, "right");
		});
		$(".featured-articles .arrow-gradient-left").mouseenter(function() {
			timeOut = setInterval(doHover, 10, "left");
		});
		$(".featured-articles .arrow-gradient").mouseleave(function() {
			clearInterval(timeOut);
		});
	} else {
		// Has touch
		$(".featured-articles .arrow-gradient").css("display", "none");

		// Bind on pan event (slides under your finger/cursor)
		hammerSlider.on('pan', panHandler);
	}

}