function updateSlideCounter() {
	// glideJS doesn't support counting the amount of slides. But we've hidden the nav dots (.slider__nav)
	// We can count them with JS and use the current class to decide how many slides there are and where we are
	// Then we can output things like 2/20 or 1/5 (slides)

	var curSlide = 1;
	var totalSlides = 1;
	// defaults

	var slideElements = $(".slider__nav a");
	var curElement = $(".slider__nav a.slider__nav-item--current");
	curSlide = ( slideElements.index(curElement) ) + 1;
	totalSlides = slideElements.length;

	$(".curSlide").html(curSlide);
	$(".totalSlides").html(totalSlides);
}

if(doFrontPageSlider === true) {
	$(".slider").glide({
		//autoplay: 4000,
		autoplay: false,
		hoverpause: true,
		circular: true,
		transitionType: 'slide',
		animationDuration: 500, // in ms
		navigation: true,
		arrows: true,
		arrowLeftText: '<span class="triangle left"></span>',
		arrowRightText: '<span class="triangle right"></span>',

		afterTransition: function() {
			updateSlideCounter();
		},

		afterInit: function() {

			var curSlide = 1;
			var totalSlides = 1;
			// defaults

			// Create slide counter element on load
			$(".slider__arrows").append('<span><span class="curSlide">' + curSlide + '</span>/<span class="totalSlides">' + totalSlides + '</span></span>');

			// Update slide counter on load
			updateSlideCounter();
		}

	});

	// http://glide.jedrzejchalubek.com



}
