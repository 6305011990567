$(function(){

	if($(window).width() <= 850){
		$('.menu-item').on('click', function(){
			if($(this).hasClass('dropdown')){
				if($(this).hasClass('open')){
					// close modal
					$('#menu-hoofdmenu').height('auto');
				} else {
					// open modal
					$('#menu-hoofdmenu').height('236');
				}
			}
		});
	}

});

$('#form-search').on("shown.bs.collapse", function(){
	$("#main-search").focus();
});

function autofocusSearch() {
		var inputContainer = $("#form-search");
		var inputField = $("#main-search");
		var button = $(".navbar-search-mobile");

		inputField.focus();

		button.on('click', function() {
			if( inputContainer.hasClass('in') ) {
				// Nothing
			}
			else {
				inputField.focus();
			}
		});
	}